<template>
    <div v-if="banner_type === 'BANNERS-ONE-PER-SLIDE'" class="white banners-slider">
        <v-carousel hide-delimiters :continuous="true" :cycle="true" :show-arrows="false" height="auto" v-model="bannerIndex">
            <div style="height: fit-content;" v-for="banner, index in banners" :key="index">
                <v-carousel-item>
                    <div class="d-flex justify-center align-center">
                        <img class="imageTag_container desktop-image rounded-lg clickable-pointer" :src="banner.filename_desktop" alt="Home Screen Banner" @click="redirectTo(banner)">
                        <img class="imageTag_container mobile-image rounded-lg clickable-pointer" :src="banner.filename_mobile" alt="Home Screen Banner" @click="redirectTo(banner)">
                    </div>
                </v-carousel-item>
            </div>
        </v-carousel>
        <div v-if="banners.length > 1" class="d-flex justify-center align-center">
            <div v-for="i in banners.length">
                <div class="px-1" v-if="(i-1) == bannerIndex">
                    <img src="https://d3vsnl8idgwrlw.cloudfront.net/active_carousel_item_icon.svg" alt="active-carousel-item">
                </div>
                <div v-else class="px-1">
                    <img src="https://d3vsnl8idgwrlw.cloudfront.net/inactive_carousel_item_icon.svg" alt="active-carousel-item">
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
export default {
    name: 'bannersComponent',
    components: {
        
    },
    props: {
        banners: {
            type: Array,
            requied: true
        },
        banner_type: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            screenWidth: window.innerWidth,
            bannerIndex: 0,
        }
    },
    watch: {
		screenWidth(newWidth, oldWidth) {
			
		},
	},
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
			// console.log('Handle Resize method called');
			this.screenWidth = window.innerWidth;
		},
        redirectTo(banner) {
            if (banner.route_to == 'CustomerHomeScreen') {
                // Needs change after index.js is changed to '/customer/teleconsultationHome'.
                window.open(window.location.origin + '/', '_blank');
            } else {
                this.$router.push({
                    name: banner.route_to
                });
            }
            // if (banner.route_to == 'BOOK_APPOINTMENT') {
            //     this.$router.push({
            //         name: "newBookingPage"
            //     });
            // } else if (banner.route_to == 'FEATURE_SPECIALITY') {
            //     localStorage.setItem('bookingType', 'featureSpecialityAppointment')
            //     this.$router.push({
            //         path: '/customer/viewDoctors?type=SpecPromotion&type1=' + banner.titleKey
            //     });
            // } else if (banner.route_to == 'ALL_CATEGORIES') {
            //     this.$router.push({
            //         name: "SpecializationsPage",
            //     });
            // } else if (banner.route_to == 'WALKIN_DOCTOR_LIST') {
            //     this.$router.push({
            //         path: '/customer/viewDoctors?type=Walkin&type1=Walkin'
            //     });
            // }
        }
    },
    beforeUnmount() {
		window.removeEventListener('resize', this.handleResize);
	},
}
</script>

<style lang="scss">
// .banners-slider {
//     @media (max-width: 600px) {
//         height: 180px;
//     }

//     @media (min-width: 601px) and (max-width: 1264px) {
//         height: 308px;
//     }

//     @media (min-width: 1265px) {
//         height: 388px;
//     }
// }
.imageTag_container {
    width: 100%;
    object-fit: fill;

    @media (max-width: 600px) {
        height: 169px;
    }

    @media (min-width: 601px) and (max-width: 1264px) {
        height: 300px;
    }

    @media (min-width: 1265px) {
        height: 380px;
    }
}

.desktop-image {
    @media (max-width: 600px) {
        display: none;
    }

    @media (min-width: 601px) and (max-width: 1264px) {
        
    }

    @media (min-width: 1265px) {
        
    }
}

.mobile-image {
     @media (max-width: 600px) {
        
     }

    @media (min-width: 601px) and (max-width: 1264px) {
        display: none;
    }

    @media (min-width: 1265px) {
        display: none;
    }
}
</style>